<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card elevation="10" data-testid="container-user">
      <v-card-title>
        <span class="headline" v-html="getTitle()">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-form data-vv-scope="form-users" data-testid="form-users">
          <v-container>
            <v-row>
              <!-- eslint-disable -->
              <v-alert dense text type="success" v-if="restPasswordSended" data-testid="notification-password-sended">
                On <strong>{{ activeItem.email }}</strong> was send message
                with password recovery link. Please folow the instructions to
                reset password.
              </v-alert>
              <!-- eslint-enable -->

              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="First name"
                  v-model="activeItem.first_name"
                  :error-messages="errors.collect('form-users.first_name')"
                  v-validate="'required'"
                  data-vv-name="first_name"
                  data-vv-validate-on="change"
                  data-testid="first-name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Last name"
                  v-model="activeItem.last_name"
                  :error-messages="errors.collect('form-users.last_name')"
                  v-validate="'required'"
                  data-vv-name="last_name"
                  data-vv-validate-on="change"
                  class="ml-2"
                  data-testid="last-name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Email"
                  v-model.trim="activeItem.email"
                  :error-messages="errors.collect('form-users.email')"
                  v-validate="'required|emailWithSpaces'"
                  data-vv-name="email"
                  data-vv-validate-on="change"
                  :readonly="editedIndex == -1 ? false : true"
                  data-testid="email"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="$can('create', 'Superadmin')"
                  v-model="activeItem.group_key"
                  :error-messages="errors.collect('form-users.company')"
                  data-vv-name="company"
                  data-vv-validate-on="change"
                  v-validate="'required'"
                  class="hide-element"
                  data-testid="company-text-superuser"
                />
                <CompanyFilter
                  label="Company"
                  v-if="$can('create', 'Superadmin')"
                  v-on:update:selected="updateCompany($event)"
                  :errorMessages="errors.collect('form-users.company') || []"
                  :findActiveElement="initGroup"
                  :dialog="dialog"
                  data-testid="company-select"
                />
                <v-text-field
                  v-else
                  outlined
                  type="text"
                  label="Company"
                  readonly
                  disabled
                  v-model="companyNameForCompanyAdmin"
                  data-testid="company-text"
                />
              </v-col>
              <v-col cols="12" sm="12" v-if="editedIndex === -1 || isSuperUser">
                <v-row class="pa-3 d-flex align-baseline">
                  <v-switch
                    v-model="enableChangePassword"
                    v-if="editedIndex !== -1"
                    inset
                    dense
                    color="success"
                    hint="Change?"
                    persistent-hint
                    data-testid="enable-change-password"
                  />
                  <div style="flex: 1 1 60%">
                    <v-text-field
                      outlined
                      v-model="activeItem.password"
                      :append-icon="showPass ? 'visibility' : 'visibility_off'"
                      :type="showPass ? 'text' : 'password'"
                      label="Password"
                      hint="Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char"
                      counter
                      @click:append="showPass = !showPass"
                      :error-messages="errors.collect('form-users.password')"
                      v-validate="'required'"
                      data-vv-name="password"
                      data-vv-validate-on="change"
                      :disabled="!canDoUpdatePassword"
                      data-testid="password"
                    />
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" sm="9">
                <v-select
                  outlined
                  v-model="activeItem.role_key"
                  :items="roles"
                  item-text="name"
                  item-value="_key"
                  :menu-props="{ maxHeight: '400', light: false }"
                  label="Role"
                  :error-messages="errors.collect('form-users.role_key')"
                  data-vv-name="role_key"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  data-testid="role"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-switch
                  v-model="activeItem.is_active"
                  label="Active"
                  input-value="true"
                  data-vv-name="is_active"
                  data-vv-validate-on="change"
                  data-testid="active"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          @click.stop="resetPassword(activeItem.email)"
          v-if="editedIndex > -1"
          data-testid="btn-reset-user-password"
        >
          Reset password
        </v-btn>
        <v-btn
          color="success"
          text
          @click.stop="save()"
          data-testid="btn-save-user"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import CompanyMixin from "@/mixins/group-mixin.js"
import CompanyFilter from "@/components/filters/CompanyFilter"

export default {
  name: "users-dialog-crud",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    roles: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  components: {
    CompanyFilter,
  },

  mixins: [GeneralMixin, CompanyMixin],

  data() {
    return {
      showPass: false,
      restPasswordSended: false,
      initGroup: "",
      companyNameForCompanyAdmin: "",
      enableChangePassword: false,
    }
  },

  watch: {
    dialog(val) {
      let self = this
      if (val) {
        self.initGroup = self.activeItem.group_key
        if (self.activeItem.group_key) {
          self.$store
            .dispatch("getUserGroup", self.activeItem.group_key)
            .then((g) => {
              self.companyNameForCompanyAdmin = g?.name
            })
        }
      }
    },
  },

  methods: {
    updateCompany(event) {
      this.activeItem.group_key = event
    },
    getTitle() {
      return this.editedIndex == -1
        ? "Add new user"
        : `<span class="blue-grey--text text--lighten-4">Edit user</span> ${this.getFullname(
            this.activeItem
          )}`
    },
    createUser(data) {
      let self = this
      self.$validator.errors.clear("form-users")
      self.$validator.validateAll("form-users").then((result) => {
        if (result) {
          self.$store
            .dispatch("userCreate", data)
            .then(({ data }) => {
              self.$emit("user-data-saved")
              self.closeDialog()
              let params = {
                text:
                  "User " +
                  self.getFullname(this.activeItem) +
                  " was created successfully.",
                show: true,
              }
              self.$root.$emit("snackbar", params)
            })
            .catch(({ response }) => {
              self.handleValidationErrors(response, "form-users")
            })
        }
      })
    },
    editUser(data, save) {
      let self = this
      self.$validator.errors.clear("form-users")
      let saveData = save || false
      if (saveData) {
        self.$validator.validateAll("form-users").then((result) => {
          if (result) {
            self.$store
              .dispatch("userEdit", data)
              .then(({ data }) => {
                self.$emit("user-data-saved")
                self.closeDialog()
                let params = {
                  text:
                    "User " +
                    self.getFullname(this.activeItem) +
                    " was edited successfully.",
                  show: true,
                }
                self.$root.$emit("snackbar", params)
              })
              .catch(({ response }) => {
                self.handleValidationErrors(response, "form-users")
              })
          }
        })
      }
    },
    save() {
      let self = this
      let email = self.activeItem.email
      let formData = {
        data: {
          first_name: self.activeItem.first_name,
          last_name: self.activeItem.last_name,
          email: email.toString().trim(),
          group_key: self.activeItem.group_key,
          role_key: self.activeItem.role_key,
          is_active: self.activeItem.is_active,
        },
      }
      if (this.canDoUpdatePassword) {
        formData.data.password = self.activeItem.password
      }
      if (this.editedIndex > -1) {
        formData["slug"] = self.editedIndex
        self.editUser(formData, true)
      } else {
        self.createUser(formData)
      }
    },
    deleteItem(item) {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      self.$store.dispatch("userDelete", item).then(({ data }) => {
        self.$emit("user-data-saved")
        self.$store.dispatch("saveTableLoaderAction", false)
      })
    },
    closeDialog() {
      this.$emit("update:dialog", false)
      this.$validator.errors.clear("form-users")
    },
    toggleAll() {
      if (this.selectedEngines.length) this.selectedEngines = []
      else this.selectedEngines = this.engines.slice()
    },
    resetPassword(email) {
      let self = this
      const data = { email: email }
      self.$store.dispatch("resetPassword", data).then((result) => {
        self.restPasswordSended = true
        setTimeout(function () {
          self.restPasswordSended = false
        }, 3000)
      })
    },
  },

  computed: {
    ...mapGetters({
      isSuperUser: "isSuperUser",
    }),
    canDoUpdatePassword() {
      return (
        this.editedIndex === -1 ||
        (this.editedIndex !== -1 &&
          this.isSuperUser &&
          this.enableChangePassword)
      )
    },
  },
}
</script>
