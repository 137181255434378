<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Users</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'users') || $can('create', 'Superadmin')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createUser()"
          data-testid="btn-create-user"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <users-dialog-crud
          :dialog.sync="dialog"
          :editedIndex="editedIndex"
          :activeItem="activeItem"
          :roles="usersRolesList"
          @user-data-saved="fetchTableData"
          ref="refDialogUser"
        />
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="table"
          :calculate-widths="true"
          :loading="showTableLoader"
          :headers="headers"
          :items="getUsersList.users"
          :server-items-length="getUsersList.full_count"
          class="elevation-0 border"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          keep="_key,first_name,last_name,email,group,role,is_active,is_deleted"
          data-testid="table-users"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="pa-3">
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
              <v-col cols="12" md="4" v-if="$can('read', 'Superadmin')">
                <CompanyFilter
                  :hideDetails="true"
                  v-on:update:selected="onCompanyFilterChange($event)"
                  data-testid="filter-company"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if="$can('delete', 'Superadmin') || $can('delete', 'users')"
              >
                <v-switch
                  v-model="showDeletedUsers"
                  label="Show deleted users"
                  input-value="true"
                  hide-details
                  data-testid="filter-show-deleted-users"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <!-- Body -->
          <template v-slot:[`item.first_name`]="{ item }">
            <div data-testid="user-first-name">
              {{ item.first_name }}
            </div>
          </template>
          <template v-slot:[`item.last_name`]="{ item }">
            <div data-testid="user-last-name">
              {{ item.last_name }}
            </div>
          </template>
          <!-- {{ getCompanyName(item.group_key)  }} -->
          <template v-slot:[`item.group.name`]="{ item }">
            <div
              v-if="$can('read', 'Superadmin') || $can('read', 'groups')"
              data-testid="user-company"
            >
              {{ item.group.name }}
            </div>
            <div data-testid="user-company" v-else>
              {{ currentUserCompanyInfo }}
            </div>
          </template>

          <template v-slot:[`item.role`]="{ item }">
            <div
              v-if="$can('read', 'Superadmin') || $can('read', 'roles')"
              data-testid="user-role"
            >
              {{ item.role }}
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <div data-testid="user-status">
              <div :class="item.is_active ? 'light-green--text' : 'red--text'">
                <span class="font-weight-regular">
                  {{ item.is_active ? "Active" : "Inactive" }}
                </span>
              </div>
              <!-- {{item.is_deleted}} -->
              <div
                v-if="
                  ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                  item.is_deleted
                "
              >
                <span class="caption">Is deleted</span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                  data-testid="btn-user-actions-more"
                  v-if="
                    $can('update', 'users') ||
                    $can('delete', 'users') ||
                    $can('update', 'Superadmin') ||
                    $can('delete', 'Superadmin')
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  @click="editUser(item)"
                  v-if="$can('update', 'users') || $can('update', 'Superadmin')"
                  data-testid="btn-edit-user"
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit user</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deleteItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    !item.is_deleted
                  "
                  data-testid="btn-delete-user"
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete user</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="restoreDeletedItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    item.is_deleted
                  "
                  data-testid="btn-restore-user"
                >
                  <v-list-item-content>
                    <v-list-item-title>Restore user</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.play">
                      mdi-backup-restore
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="permanentlyDeleteItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    item.is_deleted
                  "
                  data-testid="btn-permanently-delete-user"
                >
                  <v-list-item-content>
                    <v-list-item-title>Remove user</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete-forever
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('delete', 'users') ||
                $can('update', 'users') ||
                $can('update', 'Superadmin') ||
                $can('delete', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editUser(item)"
                  v-if="$can('update', 'users') || $can('update', 'Superadmin')"
                  icon="mdi-pencil"
                  text="Edit user"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-user"
                />
                <VerticalButton
                  @click.native="deleteItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    !item.is_deleted
                  "
                  icon="mdi-delete"
                  text="Delete user"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-user"
                />
                <VerticalButton
                  @click.native="restoreDeletedItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    item.is_deleted
                  "
                  icon="mdi-backup-restore"
                  text="Restore user"
                  :color="Pallette.actionButtons.play"
                  data-testid="btn-restore-user"
                />
                <VerticalButton
                  @click.native="permanentlyDeleteItem(item)"
                  v-if="
                    ($can('delete', 'users') || $can('delete', 'Superadmin')) &&
                    item.is_deleted
                  "
                  icon="mdi-delete-forever"
                  text="Remove user"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-permanently-delete-user"
                />
              </v-row>
            </td>
          </template>
          <!-- Body end -->
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { cloneDeep, forEach } from "lodash"
import UsersDialogCrud from "./UsersDialogCrud"
import Confirm from "@/components/Confirm"
import CompanyMixin from "@/mixins/group-mixin.js"
// import RolesMixin from "@/mixins/role-mixin.js"
import BackButtonMixin from "@/mixins/close-modal-on-backbutton.js"
import CompanyFilter from "@/components/filters/CompanyFilter.vue"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"
import UserService from "@/common/api/user.service"

export default {
  components: {
    UsersDialogCrud,
    Confirm,
    CompanyFilter,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [BackButtonMixin, CompanyMixin],

  data() {
    return {
      dialog: false,
      showDeletedUsers: false,
      headers: [
        {
          text: "First name",
          align: "left",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last name",
          align: "left",
          value: "last_name",
          sortable: true,
        },
        {
          text: "E-mail",
          value: "email",
          sortable: true,
        },
        {
          text: "Company",
          value: "group.name",
          sortable: false,
        },
        {
          text: "Role",
          value: "role.name",
          sortable: false,
        },
        {
          text: "Status",
          value: "is_active",
          sortable: true,
          width: 100,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          width: 50,
        },
      ],
      editedIndex: -1,
      activeItem: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_deleted: false,
        group_key: null,
        role_key: null,
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_deleted: false,
        group_key: null,
        role_key: null,
        is_active: true,
      },
      filterSearch: "",
      filterByCompany: null,
      usersRolesList: [],
    }
  },

  mounted: function () {
    if (!this.$can("read", "users")) {
      if (!this.$can("read", "Superadmin")) {
        this.$router.push({ name: "home" }).catch((err) => {
          // handle router push error
        })
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    filterSearch() {
      this.fetchTableData()
    },
    filterByCompany() {
      this.fetchTableData()
    },
    showDeletedUsers(val) {
      this.fetchTableData()
    },
  },

  methods: {
    onCompanyFilterChange(event) {
      this.filterByCompany = event
    },
    fetchTableData: function () {
      let self = this
      if (self.$can("read", "users") || self.$can("read", "Superadmin")) {
        let getParams = self.$refs.table.getTableServerParams()
        if (self.filterByCompany) getParams.group_key = self.filterByCompany
        if (self.filterSearch !== "") getParams.search = self.filterSearch
        if (self.showDeletedUsers) getParams.is_deleted = self.showDeletedUsers
        self.$store.dispatch("usersGetList", { params: getParams })
      }
    },
    createUser() {
      let self = this
      self
        .fetchUsersRoles()
        .then(() => {
          if (
            self.$can("create", "users") ||
            this.$can("create", "Superadmin")
          ) {
            self.activeItem = cloneDeep(self.defaultItem)
            self.editedIndex = -1
            if (!self.isSuperUser) {
              self.activeItem.group_key = self.currentUserCompanyInfo?._key
            }
            self.dialog = true
          }
        })
        .catch((err) => {
          console.log("Error on fetchUsersRoles", err)
        })
    },
    editUser(item) {
      let self = this
      self
        .fetchUsersRoles({})
        .then(() => {
          if (
            self.$can("update", "users") ||
            this.$can("update", "Superadmin")
          ) {
            self.dialog = true
            self.activeItem = cloneDeep(item)
            self.activeItem.group_key = item.group._key
            self.activeItem.role_key = item.role._key
            delete self.activeItem.group
            delete self.activeItem.role
            self.editedIndex = item._key
          }
        })
        .catch((err) => {
          console.log("Error on fetchUsersRoles", err)
        })
    },
    deleteItem(item) {
      let self = this
      if (self.$can("delete", "users") || this.$can("delete", "Superadmin")) {
        self.$refs.confirmDialog
          .open("Delete", "Are you sure you want to delete this user?", {
            color: "red darken-4",
          })
          .then((confirm) => {
            if (confirm) {
              self.$store
                .dispatch("userDelete", item)
                .then((response) => {
                  // console.log(response)
                  if (response.data.status === "success") self.fetchTableData()
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
      }
    },
    permanentlyDeleteItem(item) {
      let self = this
      if (self.$can("delete", "users") || this.$can("delete", "Superadmin")) {
        self.$refs.confirmDialog
          .open(
            "Delete",
            "Are you sure you want to permanently delete this user?",
            { color: "red darken-4" }
          )
          .then((confirm) => {
            if (confirm) {
              // console.log('confirm permanently delete user', {item})
              self.$store
                .dispatch("permanentlyUserDelete", item)
                .then((response) => {
                  if (response.data.status === "success") self.fetchTableData()
                })
                .catch((error) => {
                  console.log("permanentlyDeleteItem error", item)
                  console.log(error)
                })
            }
          })
      }
    },
    restoreDeletedItem(item) {
      let self = this
      if (self.$can("update", "users") || this.$can("update", "Superadmin")) {
        self.$refs.confirmDialog
          .open("Restore", "Are you sure you want to restore this user?", {
            color: "red darken-4",
          })
          .then((confirm) => {
            if (confirm) {
              let formData = {
                slug: item._key,
                data: {
                  first_name: item.first_name,
                  last_name: item.last_name,
                  email: item.email,
                  group_key: item.group._key,
                  role_key: item.role._key,
                  is_active: item.is_active,
                  is_deleted: false,
                },
              }
              self.$store
                .dispatch("userEdit", formData)
                .then(({ data }) => {
                  self.fetchTableData()
                  let params = {
                    text:
                      "User " +
                      self.getFullname(item) +
                      " was restored successfully.",
                    show: true,
                  }
                  self.$root.$emit("snackbar", params)
                })
                .catch(({ response }) => {
                  console.log("restoreDeletedItem error")
                  console.log(response)
                })
            }
          })
      }
    },
    close() {
      this.dialog = false
      this.fetchTableData()
      setTimeout(() => {
        this.editedIndex = -1
      }, 300)
    },
    handleValidationErrors(response) {
      let self = this
      forEach(response.data, function (val, key) {
        self.$validator.errors.add({
          scope: "form-user",
          field: key,
          msg: val,
        })
      })
    },
    fetchUsersRoles(params = { is_active: 1 }) {
      let self = this
      return new Promise((resolve, reject) => {
        let fetchData = {
          params: {
            p: 1,
            pp: 500,
            keep: "_key,name,permissions,is_active",
          },
        }
        fetchData.params = { ...fetchData.params, ...params }
        UserService.userRolesList(fetchData)
          .then((response) => {
            let rolesList = response.data.data.result
            self.usersRolesList = self.isSuperUser
              ? rolesList
              : rolesList.filter(
                  (r) => !r.permissions.includes("__superuser__")
                )
            resolve()
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      getUsersList: "getUsersList",
      currentUserCompanyInfo: "getCurrentUserCompanyInfo",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),
  },
}
</script>

<style lang="scss" scoped>
.tr-pointer {
  cursor: pointer;
}

.selectPagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  button,
  span {
    flex: 0 0 auto;
  }
}
</style>
